import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { QueryService } from "services/query.service";
import { deleteProfile, getAllProfile } from "../api/profile.api";

export enum ProfileQueryEnum {
  GET_ALL_PROFILE = "get-all-profile",
}

export const useGetAllProfileQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: ProfileQueryEnum.GET_ALL_PROFILE,
    queryFn: getAllProfile,
  });
  return { data, isLoading };
};

export const useDeleteProfile = () => {
  const queryClient = QueryService.getQueryClient();
  const { mutate, isLoading } = useMutation(deleteProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries([ProfileQueryEnum.GET_ALL_PROFILE]);
    },
    onError: (error: any) => {
      toast(
        error?.response?.data?.message ||
          error?.message ||
          "Something went wrong while creating leads",
        {
          type: "error",
        }
      );
    },
  });

  return { deleteProfile: mutate, isDeletingProfile: isLoading };
};
