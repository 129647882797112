export function truncateText(text: string, length = 50) {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  }
  return text;
}

export function removeFirstAndLastSpace(str: string) {
  let result = str;
  if (str.startsWith(" ")) {
    result = result.substring(1);
  }
  if (result.endsWith(" ")) {
    result = result.slice(0, -1);
  }
  return result;
}
